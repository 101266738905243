.you-tube-you-tube {
  width: 280px;
  height: 120px;
  display: flex;
  align-items: center;
  border-radius: 200px;
  justify-content: center;
  background-color: var(--dl-color-gray-white);
}
.you-tube-pasted-image {
  width: 50px;
}

@media(max-width: 479px) {
  .you-tube-you-tube {
    width: 120px;
    height: 60px;
    align-items: center;
  }
  .you-tube-pasted-image {
    width: 30px;
    margin-left: 15px;
  }
}
