.carousel * {max-width:100%;	max-height:100%;}
.carousel {
  width: 100%;
  height: 100%;
  position: absolute;
  -webkit-transform-style: preserve-3d;
		  transform-style: preserve-3d;
}
.carousel > * {
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  margin: 0;
  padding:0;
}

.carousel.backface > * {
  -webkit-backface-visibility: hidden;
		  backface-visibility: hidden;
}
.carousel {
  -webkit-transition: -webkit-transform 1s;
		  transition: transform 1s;
}
.carousel > *{
  -webkit-transition: opacity 1s, -webkit-transform 1s;
		  transition: opacity 1s, transform 1s;
}
/**/
.floatleft{	float :left;}
.floatright{float :right;}
.textalignleft{	text-align:left;}
#ctrls {
	position:fixed;
	bottom:0;
}
figure {
	display: block;
	-webkit-margin-before: 0;
	-webkit-margin-after: 0;
	-webkit-margin-start: 0;
	-webkit-margin-end: 0;
}
.wrapper{
width:100%;
overflow:hidden;

}
#dots {
	width: 100%;
	float: left;
}
.dotOff {
	width: 15px;
	height:15px;
	background-color: gray;
	float: left; 
	border-radius: 100%;
	margin: 1%;
	position:relative;
}
.dotOn{background-color: blue;}
.container,#container{
	width: 40%; 
	position: relative;
	margin: 50px 30%;
}
#container::after,.dotOff ::after{
	content:'';
	display:block;
	padding-top:100%;
}
.carousel > * {   
	/* opacity:0.8;    */
	/* background-color: radial-gradient(rgb(240, 240, 240), rgb(190, 190, 190)); */
	background-color: transparent;
	text-align: center;
	width:90%;
	height:90%;
	left:5%; 
	top:5%;  
	border-radius: 	25px 25px 25px 25px;
	box-shadow:		1px 1px 1px 1px #999;
}
.threeD{
	-webkit-perspective: 800px;
			perspective: 800px;
}
.twoD{
	border: 1px solid;
	overflow: hidden;
}
.twoD >*>*{
	width:100%;
	height:100%;
	left:0; 
	top:0; 
	border-radius: 	0;	
}
@media screen and (max-width: 768px) {
    .carousel {
      height: 200px; /* Adjust height for smaller screens */
    }
  }
  
  @media screen and (max-width: 480px) {
    .carousel {
      height: 150px; /* Further adjust height for even smaller screens */
    }
  }