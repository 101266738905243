/* .responsive-video {
    border-radius: 45px;
    width: 100%;
    height: auto;
    max-width: 100%;
    display: block;
    margin: 0 auto;
  }
   */

   .responsive-video {
    border-radius: 45px;
    width: 1024; /* Default width */
    /* height: auto; */
    display: block;
    margin: 0 auto;
  }
  
  /* Large screens */
  @media (min-width: 1200px) {
    .responsive-video {
      width: 1024px;
    }
  }
  
  /* Medium screens */
  @media (min-width: 768px) and (max-width: 1199px) {
    .responsive-video {
      width: 565px;
    }
  }
  
  /* Small screens */
  @media (max-width: 767px) {
    .responsive-video {
      width: 305px;
    }
  }
  